import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'We are dedicated to representing injured workers in California through a team approach. We also represent personal injury cases and select criminal cases.',
            },
          ]}
        >
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Pérez Law., PC" />
          <meta
            property="og:description"
            content="Attorneys at Law | Abogados"
          />
          <meta property="og:url" content="http://perezlawcorp.com/" />
          <meta property="og:site_name" content="Pérez Law., PC" />
          <meta
            name="twitter:text:title"
            content="Welcome to the Pérez Law Corporation"
          />
          <meta name="twitter:card" content="summary" />
          <html lang="en" />
        </Helmet>
        <div>{children}</div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
